<div [@routerTransition] class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid ">
        <div
            id="kt_aside"
            class="aside aside-{{ currentTheme.baseSettings.menu.asideSkin }} aside-hoverable aside-style"
            data-kt-drawer="true"
            data-kt-drawer-name="aside"
            data-kt-drawer-activate="{default: true, lg: false}"
            data-kt-drawer-overlay="true"
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction="start"
            data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        >
            <default-brand></default-brand>
            <div class="aside-menu flex-column-fluid">
                <div
                    id="kt_aside_menu_wrapper"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-height="auto"
                    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
                    data-kt-scroll-wrappers="#kt_aside_menu"
                    data-kt-scroll-offset="0"
                    class="hover-scroll-overlay-y my-5 my-lg-5 "
                >
                    <side-bar-menu></side-bar-menu>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
            <div id="kt_header" class="header align-items-stretch">
                <div class="{{ containerClass }} d-flex align-items-stretch justify-content-between">
                    <!-- begin aside toggler -->
                    <div title="Show aside menu" class="d-flex align-items-center d-lg-none ms-n3 me-1">
                        <div
                            id="kt_aside_mobile_toggle"
                            class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                        >
                            <span _ngcontent-jnd-c46="" class="svg-icon svg-icon-2x mt-1">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                                        fill="black"
                                    ></path>
                                    <path
                                        opacity="0.3"
                                        d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </span>
                            <!---->
                        </div>
                    </div>
                    <!-- end aside toggler-->
                    <!-- begin mobile logo -->
                    <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <default-logo [customHrefClass]="'d-lg-none'" [skin]="appSession.theme.baseSettings.layout.darkMode ? 'dark-sm': 'light-sm' "></default-logo>
                    </div>
                    <!-- end mobile logo -->
                    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                            <div class="d-flex align-items-stretch" id="kt_header_nav"></div>
                            <div class="d-flex align-items-stretch flex-shrink-0">
                                <div class="d-flex align-items-stretch flex-shrink-0">
                                    <active-delegated-users-combo
                                        *ngIf="!installationMode"
                                    ></active-delegated-users-combo>
                                    <subscription-notification-bar></subscription-notification-bar>
                                    <quick-theme-selection></quick-theme-selection>
                                    <!-- <language-switch-dropdown></language-switch-dropdown> -->
                                    <header-notifications></header-notifications>
                                    <chat-toggle-button></chat-toggle-button>
                                    <user-menu></user-menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="flex: 1">
                <router-outlet></router-outlet>
            </div>
            <footer-bar></footer-bar>
        </div>
    </div>
</div>
